<template>
  <v-container>
    <v-row align='center' justify='center'>
      <v-col cols='12' sm='8' md='6' lg='4'>
        <h1>404</h1>
        <h4>Sorry, we can't find the page you were looking for!</h4>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  export default {}
</script>
